.article {
  border-left: 3px solid var(--gray-dark-border);
  padding: 1.5rem;
  text-align: left;
  position: relative;
  background: #fff;
  box-shadow: 1px 1px 6px 1px var(--gray-shadow);
  border-radius: 3px;
  display: grid;
  grid-template-rows: auto auto 1fr;
  word-break: break-word;
  height: 100%;

  @media screen and (min-width: 45em) {
    padding: 2rem;
  }
}

.articleHeader {
  overflow: hidden;
}

.articleTitle {
  width: 100%;
  margin-top: 0;

  &Truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.articleTitleLink {
  display: block;
}

.meta {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 700;
}

.favicon {
  margin-right: 0.25rem;
  vertical-align: top;
}

.blogName {
  display: inline-block;
  margin-bottom: 0.5rem;

  @media screen and (min-width: 24em) {
    margin-bottom: 0;
  }
}

.articleDate {
  font-weight: 400;
  display: inline-block;
  color: var(--gray-text);

  @media screen and (min-width: 24em) {
    &::before {
      content: '| ';
    }
  }
}

.footer {
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.footerList {
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.footerList,
.footerItem {
  margin: 0;
  padding: 0;
}

.footerItem:not(:last-child) {
  margin-right: 2rem;
}

.footerLink {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1em;

  @media screen and (min-width: 45em) {
    font-size: 1.2em;
  }
}

.icon {
  margin-right: 0.5rem;
}

.excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
