.list {
  list-style: none;
  width: 100%;
  padding: 0;
}

.listItem {
  width: 100%;
  margin: 1rem 0;
}

.listItem:hover {
  background: var(--gray-border);
}
